import { DIALOG_SCREEN_STATES } from "./Config.EJX";
import { UIDiv } from "./libs/ui";
import { createEJXButton } from "./utils.EJX";
import { showThreeLaunchButton } from '@eyejack/ejx-launcher-button';
import spinnerImage from '../images/spinner.png';
import '@eyejack/ejx-launcher-button/styles.css'
import '../css/XRButton.css'
import { retry } from "./utils/retry";
import { pushNotification } from "./EJXNotification";
import { captureException } from "@sentry/astro";

/**
 * Creates a div that warns the user that the preview will expire in X minutes.
 * @param {any} editor 
 * @param {number} expiryTimestampMs The time the preview will expire as a unix epoch in milliseconds.
 * @returns {UIDiv} 
 */
function ExpiryTimer(editor, expiryTimestampMs, onExpired) {
    const container = new UIDiv();
    container.addClass('XRButton_Status');
    container.setId('xr-button-expiry-timer');

    const interval = setInterval(() => {
        const now = Date.now();
        const diff = expiryTimestampMs - now;
        const minutesRemaining = diff / 1000 / 60;
        if (minutesRemaining > 0) {
            const minutesRemainingInt = Math.ceil(minutesRemaining);
            container.dom.innerText = `Link expires in ${minutesRemainingInt} minute${minutesRemainingInt === 1 ? '' : 's'}`
        } else if (minutesRemaining >= 0) {
            const secondsRemainingInt = Math.ceil(diff / 1000);
            container.dom.innerText = `Link expires in ${secondsRemainingInt} minute${secondsRemainingInt === 1 ? '' : 's'}`;
        } else {
            onExpired();
            container.dom.remove();
            clearInterval(interval);
        }
    }, 1000);

    container.dispose = () => {
        clearInterval(interval);
        container.dom.remove();
    }

    return container;
}

function XRButton(editor, ejxPlayer) {
    const signals = editor.signals;
    const config = editor.config;
    const ejxApi = editor.ejxAPI;

    const container = new UIDiv();
    const buttonContainer = new UIDiv();
    buttonContainer.addClass('XRButton_Container');
    container.add(buttonContainer);

    let expiryTimer;

    let realPlayButton; // Contains reference to dom element of the real play button
    let hasUnsavedChanges = false;

    /**
     * @param {'expired'|'changed'} reason Reason for cleaning up the expiry timer.
     */
    const cleanupExpiryTimer = (reason) => {
        hasUnsavedChanges = true;
        if (realPlayButton) {
            realPlayButton.remove();
            if (reason === 'changed') {
                changeStatusText('Changes detected.  Press the XR button again to generate a new Play link.');
            } else if (reason === 'expired') {
                changeStatusText('The preview has expired.  Press the XR button again to generate a new Play link.');
            }
        }

        if (expiryTimer) {
            expiryTimer.dispose();
            expiryTimer = undefined;
        }
    }

    const changeStatusText = (text) => {
        let statusTextElement = buttonContainer.dom.querySelector('#xr-button-status-text');
        if (!statusTextElement) {
            const statusText = new UIDiv();
            statusText.setId('xr-button-status-text');
            statusText.setClass('XRButton_Status');
            buttonContainer.add(statusText);
            statusTextElement = statusText.dom;
        }
        statusTextElement.innerText = text;
    }

    const onProjectChanged = () => {
        cleanupExpiryTimer('changed');
    }
    const onTimerExpired = () => {
        cleanupExpiryTimer('expired');
    }

    signals.projectChanged.add( onProjectChanged );
    signals.unsetProjectChanged.add( function() {
        hasUnsavedChanges = false;
        let statusTextElement = buttonContainer.dom.querySelector('#xr-button-status-text');
        if (statusTextElement) statusTextElement.remove();
    })

	signals.ejxSaveFinished.add( function () {
		hasUnsavedChanges = false;
	} );

    const tryXRButton = createEJXButton('XR', () => {
        if (hasUnsavedChanges || !editor.config.getKey('project/id') ) {
            signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.NEEDS_SAVE );
            signals.ejxSaveFinished.addOnce(() => {
                signals.closeDialog.addOnce(() => {
                    handleShowQR();
                })
            });
        } else {
            handleShowQR();
        }
    })
    tryXRButton.classList.add('XRButton');
    buttonContainer.dom.append(tryXRButton);

    const statusElement = new UIDiv();
    statusElement.addClass('XRButton_Status');
    buttonContainer.add(statusElement);


    const loader = document.createElement("img");
    loader.src = spinnerImage.src;
    loader.classList.add('animate-spin');
    loader.classList.add("XRButton_Loader");

    const handleShowQR = async () => {
        changeStatusText("Generating QR code...");
        tryXRButton.innerHTML = '';
        tryXRButton.appendChild(loader);
        tryXRButton.disabled = true;

        let preview;
        try {
            // Need to retry / poll backend because unzipping happens asyncronously
            const latestVersion = await retry(
                () => ejxApi.getLatestVersion( config.getKey( 'user/workspace' ), config.getKey( 'project/id' ) ),
                { attempts: 5, }
            );
            preview = await ejxApi.createPreview(
                config.getKey( 'user/workspace' ),
                config.getKey( 'project/id' ),
                latestVersion.versionId,
                config.getKey( 'user/username' )
            );
            config.setKey( 'project/latestVersion', latestVersion );
            config.setKey( 'project/previewUrl', preview.url );
            config.setKey( 'project/previewId', preview.identifier );
        } catch (error) {
            console.error(error);
            captureException(error);
            pushNotification(editor, {
                type: 'error',
                title: 'Failed to create preview.  Please log out then back in and try again.'
            })
            editor.signals.stopPlayer.dispatch();
            return;
        } finally {
            if (tryXRButton.contains(loader)) tryXRButton.removeChild(loader);
            tryXRButton.innerText = 'XR';
            tryXRButton.disabled = false;
        }

        if (expiryTimer) expiryTimer.dispose();
        expiryTimer = ExpiryTimer(editor, preview.expiry, onTimerExpired);
        buttonContainer.add(expiryTimer);

        const statusTextElement = buttonContainer.dom.querySelector('#xr-button-status-text');
        if (statusTextElement) statusTextElement.remove();


        realPlayButton = showThreeLaunchButton({
            // Required
            renderer: ejxPlayer.renderer,

            // (optional) configure and enable VR launcher
            vrOptions: {
                optionalFeatures: ["local-floor", "bounded-floor", "plane-detection", "anchors"]
            },
            // (optional) configure and enable AR launcher
            arOptions: {
                optionalFeatures: ["local-floor", "bounded-floor", "plane-detection", "anchors", "hit-test"]
            },

            // (optional) Reference space for AR, defaults to local.
            arReferenceSpace: 'local-floor',
            // (optional) Reference space for VR, defaults to undefined (does not effect renderer.xr)
            vrReferenceSpace: undefined,

            appearance: {
                buttonText: 'XR',
                borderColor: '#fff',
                variant: 'minimal',
                redirectPopupOpen: true,
            },
            launchBaseUrl: import.meta.env.PUBLIC_STAGE === 'prod' ? 'https://play.eyejack.xyz/p/' : 'https://dev.play.eyejack.xyz/p/',
            launchUrl: preview.identifier,

            // AR ui overlay root element
            container: container.dom,
        });
    }

    return container;
}

export { XRButton };
