import { UIPanel, UIRow, UISpan, UISelect, UIText, UIDiv } from './libs/ui.js';

import * as THREE from 'three';

import { createEJXButton } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import { SetScaleCommand } from './commands/SetScaleCommand.js';

import defaultThumbnailImage from '../images/thumbnail_projecttype_default.jpg';
import ejxCubeThumbnailImage from '../images/thumbnail_projecttype_cube.jpg';
import { StorageService } from '../../../services/Storage.ts';

function DialogProjectType( editor ) {

	const config = editor.config;
	const signals = editor.signals;
    const strings = editor.strings;

	const container = new UIPanel();

    const instructionRow = new UIRow();
	const instruction = new UIText( 'Please select the type of project you are creating.' );
    instruction.dom.style.textAlign = 'center';
	instruction.dom.style.margin = '20px auto';
	instruction.dom.style.color = '#C0FB00';
	instructionRow.add(instruction);
	container.add(instructionRow);

    const optionRow = new UIRow();
	optionRow.dom.classList.add("thumbnail-gallery");

	let selectedThumbnail;
	const selectThumbnail = (thumbnailTag) => {
		if(selectedThumbnail) {
			selectedThumbnail.classList.remove("thumbnail-selected");
		}
		selectedThumbnail = thumbnailTag;
		selectedThumbnail.classList.add("thumbnail-selected");
		confirmButton.disabled = !!!selectedThumbnail;
	}

	// Should automate this if there's somewhere that stores all the project types
	const defaultThumbnail = new UIDiv();
	defaultThumbnail.dom.style.width = 'max-content';
	defaultThumbnail.dom.classList.add("thumbnail");
	const defaultImage = document.createElement('img');
	defaultImage.src = defaultThumbnailImage.src;
	defaultThumbnail.dom.appendChild(defaultImage);
	const defaultCaption = document.createElement('p');
	defaultCaption.innerText = "Default";
	defaultCaption.style.width = "100%";
	defaultCaption.style.textAlign = "center";
	defaultThumbnail.dom.appendChild(defaultCaption);
	defaultThumbnail.dom.onclick = () => selectThumbnail(defaultThumbnail.dom);
	defaultThumbnail.dom.value = "Default";
	optionRow.add(defaultThumbnail);

	const ejxCubeThumbnail = new UIDiv();
	ejxCubeThumbnail.dom.style.width = 'max-content';
	ejxCubeThumbnail.dom.classList.add("thumbnail");
	const ejxCubeImage = document.createElement('img');
	ejxCubeImage.src = ejxCubeThumbnailImage.src;
	ejxCubeThumbnail.dom.appendChild(ejxCubeImage);
	const ejxCubeCaption = document.createElement('p');
	ejxCubeCaption.innerText = "EJX Cube";
	ejxCubeCaption.style.width = "100%";
	ejxCubeCaption.style.textAlign = "center";
	ejxCubeThumbnail.dom.appendChild(ejxCubeCaption);
	ejxCubeThumbnail.dom.onclick = () => selectThumbnail(ejxCubeThumbnail.dom);
	ejxCubeThumbnail.dom.value = "EJXCube";
	optionRow.add(ejxCubeThumbnail);

	const confirmButton = createEJXButton('Confirm', () => {
		config.setKey( 'project/newType', selectedThumbnail.value );

		signals.closeDialog.dispatch();
		signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.NEW );

        signals.loadingFinished.addOnce( () => { signals.switchedProjectType.dispatch(); } );

        // Clear artwork to mint.
		const ss = new StorageService('ejxStorage', 'projectFiles');
        ss.init().then(() => {
            try {
                ss.destroy();
            } catch(e) {
                // Ignore failures
            }
        })
	}, 'primary');

	confirmButton.disabled = !!!selectedThumbnail;

	container.add( optionRow );

	const buttonRow = new UIRow();
	buttonRow.dom.appendChild( confirmButton );
	container.add(buttonRow);

	return container;

}

export { DialogProjectType };
