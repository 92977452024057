import * as THREE from 'three';

import { zipSync, strToU8 } from 'fflate';

import { UIPanel, UIRow, UIHorizontalRule } from './libs/ui.js';
import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import { StorageService } from '../../../services/Storage';
import { ExportUtils, ImportUtils } from './utils/importexport.js';
import { pushNotification } from './EJXNotification.js';

function MenubarFile( editor ) {

	const config = editor.config;
	const signals = editor.signals;
	const strings = editor.strings;

	const container = new UIPanel();
	container.setClass( 'menu' );

	const title = new UIPanel();
	title.setClass( 'title' );
	title.setTextContent( strings.getKey( 'menubar/file' ) );
	container.add( title );

	const options = new UIPanel();
	options.setClass( 'options' );
	container.add( options );

	// New

	let option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/new' ) );
	option.onClick( function () {

		signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.PROJECT_TYPE );
		// if ( confirm( 'Any unsaved data will be lost. Are you sure?' ) ) {

		// 	editor.clear();

		// }

	} );
	options.add( option );

    // Save 

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/save' ) );
	option.onClick( async function () {

		signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.SAVING );

	} );
	options.add( option );

    // Open

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/open' ) );
	option.onClick( function () {

		signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.OPEN );

	} );
	options.add( option );


	options.add( new UIHorizontalRule() );

	// Import

	const form = document.createElement( 'form' );
	form.style.display = 'none';
	document.body.appendChild( form );

	const fileInput = document.createElement( 'input' );
	fileInput.multiple = true;
	fileInput.type = 'file';
	fileInput.addEventListener( 'change', function () {

		editor.loader.loadFiles( fileInput.files );
		form.reset();

	} );
	form.appendChild( fileInput );

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( strings.getKey( 'menubar/file/import' ) );
	option.onClick( function () {

		fileInput.click();

	} );
	options.add( option );

	//

	options.add( new UIHorizontalRule() );
	
	// Save to Disk / Load from Disk

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( 'Save ZIP' );
	option.onClick( async function () {

		const zip = await ExportUtils.generateProjectZip(editor);
        const zipBlob = new Blob([zip], {
            type: 'application/zip'
        })

        const title = config.getKey( 'project/title' );
        save( zipBlob, ( title !== '' ? title : 'untitled' ) + '.zip' );

        editor.signals.loadingFinished.dispatch();

	} );
	options.add( option );

    // Load from Disk
	const formLoad = document.createElement( 'form' );
	formLoad.style.display = 'none';
	document.body.appendChild( formLoad );

	const fileInputLoad = document.createElement( 'input' );
	fileInputLoad.type = 'file';
    fileInputLoad.accept = '.zip';
	fileInputLoad.addEventListener( 'change', async function (event) {

        const { files } = fileInputLoad;
        if (files.length === 1) {
            try {
                const bytes = new Uint8Array(await files[0].arrayBuffer());
                const appJsonData = await ImportUtils.loadFromZip( editor, bytes);

                if (appJsonData && appJsonData.project) {
                    config.setKey('project/id', appJsonData.project.projectId);
                    config.setKey('project/title', appJsonData.project.projectTitle);
                    config.setKey('project/type', appJsonData.project.projectType);
                }

                // Need to clear project id if not exist in the user workspace
                // so if the user tries to save it saves as a new project.
                const workspaceId = config.getKey('user/workspace');
                const username = config.getKey('user/username');

                let projects = config.getKey('/projects/list');
                if (!projects && workspaceId && username) {
                    projects = await editor.ejxAPI.getProjects(workspaceId, username);
                }

                const userHasProject = projects && projects.some(p => p.projectId === appJsonData.project.projectId);
                if ( !userHasProject ) {
                    config.setKey('project/id', '');
                }

                signals.projectChanged.dispatch();

            } catch (reason) {
                let description;
                if (reason instanceof Error) {
                    description = reason.message;
                } else if (typeof reason === 'string') {
                    description = reason;
                } else if (reason.toString) {
                    description = reason.toString();
                }
                pushNotification(editor, {
                    type: 'error',
                    title: 'Failed to load from disk',
                    description,
                })
            } finally {
                editor.signals.loadingFinished.dispatch();
            }
        } else {
            console.error('ZIPOAIDO')
        }

	} );
	formLoad.appendChild( fileInputLoad );

	option = new UIRow();
	option.setClass( 'option' );
	option.setTextContent( 'Load ZIP');
	option.onClick( function () {

		fileInputLoad.click();

	} );
	options.add( option );

	const link = document.createElement( 'a' );
	function save( blob, filename ) {

		if ( link.href ) {

			URL.revokeObjectURL( link.href );

		}

		link.href = URL.createObjectURL( blob );
		link.download = filename || 'data.json';
		link.dispatchEvent( new MouseEvent( 'click' ) );

	}

	function saveArrayBuffer( buffer, filename ) {

		save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

	}

	function saveString( text, filename ) {

		save( new Blob( [ text ], { type: 'text/plain' } ), filename );

	}

	function getAnimations( scene ) {

		const animations = [];

		scene.traverse( function ( object ) {

			animations.push( ... object.animations );

		} );

		return animations;

	}

	return container;

}

export { MenubarFile };
