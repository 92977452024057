/**
 * Migrates from a default threejs editor project to an EJX editor project
 * @param {any} appJson 
 */
function migrate0To1(appJson) {
    const project = appJson.project;
    if (project.postprocessing && project.postprocessing.length > 0) {
        project.postprocessing = project.postprocessing.map((passConfig => {
            // Bloom pass config key names have changed.
            if (passConfig.type === 'bloom') {
                return {
                    type: 'bloom',
                    threshold: passConfig.bloomThreshold || passConfig.threshold || 0.05,
                    strength: passConfig.bloomStrength || passConfig.strength || 0.25,
                    radius: passConfig.bloomRadius || passConfig.radius || 0.2,
                    exposure: passConfig.bloomExposure || passConfig.exposure || 1,
                }
            }
            return passConfig;
        }))
    }
    return appJson;
}

export const Migrations = {
    migrate0To1,
}
