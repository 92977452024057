import * as THREE from 'three';
import 'codemirror/lib/codemirror.css'
import './css/main.css';

import { Editor } from './js/Editor.js';
import { Viewport } from './js/Viewport.js';
import { Toolbar } from './js/Toolbar.js';
import { Script } from './js/Script.js';
// import { Player } from './js/Player.js';
import { Player } from './js/Player.EJX.js';
import { Sidebar } from './js/Sidebar.js';
import { Menubar } from './js/Menubar.js';
import { Resizer } from './js/Resizer.js';
import { VRButton } from 'three/addons/webxr/VRButton.js';
import { Dialog } from './js/Dialog.js';
import { Overlay } from './js/Overlay.js';
import { OverlayLoading } from './js/OverlayLoading.js';
import { pushNotification } from './js/EJXNotification.js';
import { throttle } from './js/utils/retry.js';
import { captureException } from '@sentry/astro';

window.URL = window.URL || window.webkitURL;
window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;

Number.prototype.format = function () {

    return this.toString().replace( /(\d)(?=(\d{3})+(?!\d))/g, '$1,' );

};

//

const editor = new Editor();

window.EJX = window.EJX || {};
window.EJX.IS_EDITOR = true; 
window.EJX.EDITOR_VERSION == __BUILD_PKG_VERSION__;
window.EJX.editor = editor;

window.editor = editor; // Expose editor to Console
window.THREE = THREE; // Expose THREE to APP Scripts and Console
window.VRButton = VRButton; // Expose VRButton to APP Scripts

const viewport = new Viewport( editor );
document.body.appendChild( viewport.dom );

const toolbar = new Toolbar( editor );
document.body.appendChild( toolbar.dom );

const script = new Script( editor );
document.body.appendChild( script.dom );

let player = new Player( editor );
document.body.appendChild( player.dom );

const sidebar = new Sidebar( editor );
document.body.appendChild( sidebar.dom );

const menubar = new Menubar( editor );
document.body.appendChild( menubar.dom );

const resizer = new Resizer( editor );
document.body.appendChild( resizer.dom );

const dialog = new Dialog( editor );
document.body.appendChild( dialog.dom );
//
const overlay = new Overlay( editor );
document.body.appendChild( overlay.dom );

const overlayLoading = new OverlayLoading( editor );
document.body.appendChild( overlayLoading.dom );

const notifyProjectTooLarge = throttle(8000, () => {
    pushNotification(editor, {
        type: 'error',
        title: 'Project is too large to store.',
        description: 'If you refresh the page your changes will not be saved.'
    })
})

editor.storage.init( function () {

    editor.storage.get( function ( state ) {

        if ( isLoadingFromHash ) return;

        if ( state !== undefined ) {

            editor.fromJSON( state );

        }

        const selected = editor.config.getKey( 'selected' );

        if ( selected !== undefined ) {

            editor.selectByUuid( selected );

        }

    } );

    //

    let timeout;

    function saveState() {

        if ( editor.config.getKey( 'autosave' ) === false ) {

            return;

        }

        clearTimeout( timeout );

        timeout = setTimeout( function () {

            editor.signals.localSaveStarted.dispatch();

            try {
                editor.storage.set(editor.toJSON(), (byteSize) => {
                    signals.localSaveFinished.dispatch(byteSize);
                });
            } catch (error) {
                if (error instanceof Error) {
                    if (error.message.includes('serialized value is too large')) {
                        return notifyProjectTooLarge();
                    }
                }
                throw error;
            }

        }, 1000 );

    }

    const signals = editor.signals;

    signals.projectChanged.add( saveState );

    function handleAnyChange() {
        signals.projectChanged.dispatch();
    }
    signals.localSave.add( handleAnyChange );
    signals.geometryChanged.add( handleAnyChange );
    signals.objectAdded.add( handleAnyChange );
    signals.objectChanged.add( handleAnyChange );
    signals.objectRemoved.add( handleAnyChange );
    signals.materialChanged.add( handleAnyChange );
    // signals.sceneBackgroundChanged.add( handleAnyChange );
    // signals.sceneEnvironmentChanged.add( handleAnyChange );
    signals.sceneFogChanged.add( handleAnyChange );
    signals.sceneGraphChanged.add( handleAnyChange );
    signals.scriptChanged.add( handleAnyChange );
    signals.historyChanged.add( handleAnyChange );
    signals.ejxCubeSettingsChanged.add( handleAnyChange );

} );

//

document.addEventListener( 'dragover', function ( event ) {

    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';

} );

document.addEventListener( 'drop', function ( event ) {

    event.preventDefault();

    if ( event.dataTransfer.types[ 0 ] === 'text/plain' ) return; // Outliner drop

    if ( event.dataTransfer.items ) {

        // DataTransferItemList supports folders

        editor.loader.loadItemList( event.dataTransfer.items );

    } else {

        editor.loader.loadFiles( event.dataTransfer.files );

    }

} );

function onWindowResize() {

    editor.signals.windowResize.dispatch();

}

window.addEventListener( 'resize', onWindowResize );

onWindowResize();

//

let isLoadingFromHash = false;
const hash = window.location.hash;

if ( hash.slice( 1, 6 ) === 'file=' ) {

    const file = hash.slice( 6 );

    if ( confirm( 'Any unsaved data will be lost. Are you sure?' ) ) {

        const loader = new THREE.FileLoader();
        loader.crossOrigin = '';
        loader.load( file, function ( text ) {

            editor.clear();
            editor.fromJSON( JSON.parse( text ) );

        } );

        isLoadingFromHash = true;

    }

}

editor.signals.userCapabilitiesChanged.add((capabilities) => {
    for (const capabilityClass of document.body.classList) {
        if (capabilityClass.startsWith('capability--')) {
            document.body.classList.remove(capabilityClass);
        }
    }

    for (const capability of capabilities) {
        document.body.classList.add(`capability--${capability.capability}`);
    }
})
const userId = editor.config.getKey('user/username');
if (userId) {
    editor.ejxAPI.refreshUserCapabilities(userId).catch(error => {
        if (error instanceof Error && error.isAxiosError && error.response.stats === 401) {
            // Ignore token expiry errors
        } else {
            console.error('Failed to refresh user capabilities.', error)
            captureException(error);
        }
    });
}

// editor.signals.showOverlay.dispatch({
//     title: "Welcome",
//     description: "Welcome to the EJX editor",
//     buttons: [
//         {
//             text: 'Close',
//             action: () => { editor.signals.hideOverlay.dispatch() },
//             style: 'primary',
//         },
//     ],
// });
