import * as THREE from 'three';

// import { UINumber, UIPanel, UIRow, UISelect, UIText } from './libs/ui.js';
// import { UIBoolean } from './libs/ui.three.js';
import { UIDiv } from './libs/ui.js';

function SidebarProjectRenderer( editor ) {

	const signals = editor.signals;

	let currentRenderer = null;

	const container = new UIDiv();
	//
	// const headerRow = new UIRow();
	// headerRow.add( new UIText( strings.getKey( 'sidebar/project/renderer' ).toUpperCase() ) );
	// container.add( headerRow );
	//
	// // Shadows
	//
	// const shadowsRow = new UIRow();
	// container.add( shadowsRow );
	//
	// shadowsRow.add( new UIText( strings.getKey( 'sidebar/project/shadows' ) ).setWidth( '90px' ) );
	//
	// const shadowsBoolean = new UIBoolean( config.getKey( 'project/renderer/shadows' ) ).onChange( updateShadows );
	// shadowsRow.add( shadowsBoolean );
	//
	// const shadowTypeSelect = new UISelect().setOptions( {
	// 	0: 'Basic',
	// 	1: 'PCF',
	// 	2: 'PCF Soft',
	// 	//	3: 'VSM'
	// } ).setWidth( '125px' ).onChange( updateShadows );
	// shadowTypeSelect.setValue( config.getKey( 'project/renderer/shadowType' ) );
	// shadowsRow.add( shadowTypeSelect );
	//
	// function updateShadows() {
	//
	// 	currentRenderer.shadowMap.enabled = shadowsBoolean.getValue();
	// 	currentRenderer.shadowMap.type = parseFloat( shadowTypeSelect.getValue() );
	//
	// 	signals.rendererUpdated.dispatch();
	//
	// }

	//

	function createRenderer() {

		currentRenderer = new THREE.WebGLRenderer( { antialias: true } );
		currentRenderer.outputEncoding = THREE.sRGBEncoding;
		// currentRenderer.shadowMap.enabled = shadowsBoolean.getValue();
		// currentRenderer.shadowMap.type = parseFloat( shadowTypeSelect.getValue() );

		signals.rendererCreated.dispatch( currentRenderer );
		signals.rendererUpdated.dispatch();

	}

	createRenderer();


	// Signals

	signals.editorCleared.add( function () {

		currentRenderer.useLegacyLights = false;
		currentRenderer.shadowMap.enabled = true;
		currentRenderer.shadowMap.type = THREE.PCFShadowMap;
		currentRenderer.toneMapping = THREE.NoToneMapping;
		currentRenderer.toneMappingExposure = 1;

		// shadowsBoolean.setValue( currentRenderer.shadowMap.enabled );
		// shadowTypeSelect.setValue( currentRenderer.shadowMap.type );

		signals.rendererUpdated.dispatch();

	} );

	// signals.rendererUpdated.add( function () {
	//
	// 	config.setKey(
	// 		'project/renderer/shadows', shadowsBoolean.getValue(),
	// 		'project/renderer/shadowType', parseFloat( shadowTypeSelect.getValue() ),
	// 	);
	//
	// } );

	return container;

}

export { SidebarProjectRenderer };
