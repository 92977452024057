import { UIPanel, UIRow, UIText } from './libs/ui.js';

import { createHeading } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import QrCreator from 'qr-creator';

function DialogPlay( editor ) {

	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

	let workspaceId = config.getKey( 'user/workspace' );
	let projectId = config.getKey( 'project/id' );

	const playScreen = document.createElement( 'div' );

	const playRow = new UIRow();
	playRow.dom.style.display = 'flex';
	playRow.dom.style.margin = '20px 0';


	const playQRCodeContainer = document.createElement( 'div' );
	playQRCodeContainer.style.marginRight = '20px';
	const PlayProjectDetails = document.createElement( 'div' );
	playRow.dom.appendChild( playQRCodeContainer );
	const qrCanvas = document.createElement( 'canvas' );
	playQRCodeContainer.appendChild( qrCanvas );

	let PROJECT_URL = '';

	const regenerateQRCode = () => {

		PROJECT_URL = window.location.hostname === 'editor.eyejack.xyz' ? `https://play.eyejack.xyz/p/${ config.getKey( 'project/previewId' ) }` : `https://dev.play.eyejack.xyz/p/${ config.getKey( 'project/previewId' ) }`;
		projectHref.href = PROJECT_URL;
		projectHref.textContent = PROJECT_URL;

        const canvas = document.createElement('canvas');
        QrCreator.render({
            text: PROJECT_URL,
            radius: 0.1,
            ecLevel: 'H',
            fill: '#000000',
            size: 512,
        }, canvas);

		if ( playQRCodeContainer.firstElementChild )
			playQRCodeContainer.removeChild( playQRCodeContainer.firstElementChild );

        playQRCodeContainer.append(canvas);

	};

	signals.launchDialog.add( async ( screenState ) => {

		if ( screenState === DIALOG_SCREEN_STATES.PLAY ) {

			signals.setLoadingStatus.dispatch( 'Generating QR...' );
			signals.loadingStarted.dispatch();
			const latestVersion = await ejxApi.getLatestVersion( config.getKey( 'user/workspace' ), config.getKey( 'project/id' ) );
			config.setKey( 'project/latestVersion', latestVersion );
			const preview = await ejxApi.createPreview( config.getKey( 'user/workspace' ), config.getKey( 'project/id' ), config.getKey( 'project/latestVersion' ).versionId, config.getKey( 'user/username' ) );

			config.setKey( 'project/previewUrl', preview.url );
			config.setKey( 'project/previewId', preview.identifier );
			regenerateQRCode();
			signals.loadingFinished.dispatch();

		}

	} );

	signals.ejxSaveFinished.add( () => {

		workspaceId = config.getKey( 'user/workspace' );
		projectId = config.getKey( 'project/id' );

		regenerateQRCode();

		if ( PlayProjectDetails.firstElementChild )
			PlayProjectDetails.removeChild( PlayProjectDetails.firstElementChild );

		projectName.textContent = config.getKey( 'project/title' );
		PlayProjectDetails.appendChild( projectName );
		PlayProjectDetails.appendChild( projectText.dom );
		projectHref.href = PROJECT_URL;
		projectHref.textContent = PROJECT_URL;
		PlayProjectDetails.appendChild( projectHref );

	} );

	const projectName = createHeading( config.getKey( 'project/title' ) );
	const projectText = new UIText( 'Your project is available to preview for the next 15 minutes...' );
	const projectHref = document.createElement( 'a' );
	projectHref.href = PROJECT_URL;
	projectHref.target = '_blank';
	projectHref.textContent = PROJECT_URL;
	PlayProjectDetails.appendChild( projectName );
	PlayProjectDetails.appendChild( projectText.dom );
	PlayProjectDetails.appendChild( projectHref );
	playRow.dom.appendChild( PlayProjectDetails );

	playScreen.appendChild( playRow.dom );

	container.dom.appendChild( playScreen );

	return container;

}

export { DialogPlay };
