/**
 * This module manages versioning of app.json files and provides a function to ensure that
 * an app json is up to date and valid.
 *
 * When you call `VersioningUtils.migrateToLatest` it will read the version number, compare it with
 * the latest version and apply a set of changes to migrate it to the latest version.
 */

import { pushNotification } from "../EJXNotification";
import { Migrations } from "./migrations";

const LATEST_VERSION = Number.parseInt(__BUILD_PKG_SCHEMA_VERSION__);
console.log(LATEST_VERSION)

export const VersioningUtils = {
    /**
     * Gets the schema version of the app.json
     * @param {} appJson 
     */
    getVersion(appJson) {
        if (!appJson.project) return 0
        const { project } = appJson;

        if (project.version === undefined) return 0;
        return project.version;
    },
    /**
     * Checks if an app.json file is the latest version
     * @param {} appJson 
     * @returns {boolean} 
     */
    isLatest(appJson) {
        return this.getVersion(appJson) == LATEST_VERSION;
    },
    /**
     * Migrates an app.json file to the latest schema version.
     * @param {object} appJson - App JSON file
     * @throws {Error} when migration failed.
     */
    migrateToLatest(appJson) {
        if (this.isLatest(appJson)) {
            return appJson;
        }

        let data = appJson;
        const version = this.getVersion(appJson);
        for (let i = version; i < LATEST_VERSION; i++) {
            try {
                data = Migrations[`migrate${i}To${i+1}`](data);
            } catch (error) {
                const wrapped = new Error(`MigrationError: Error while migrating from ${i} to ${i+1}. [${version} -> ${LATEST_VERSION}]`);
                wrapped.cause = error;
                throw error;
            }
        }

        return data;
    }
}
