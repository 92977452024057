import { UIButton, UIPanel } from './libs/ui.js';

import { DialogLogin } from './Dialog.login.js';
import { DialogSave } from './Dialog.save.js';
import { DialogSaveAs } from './Dialog.saveAs.js';
import { DialogOpen } from './Dialog.open.js';
import { DialogNew } from './Dialog.new.js';
import { DialogProjectType } from './Dialog.projectType.js';
import { DialogThumbnail } from './Dialog.thumbnail.js';
import { DialogPlay } from './Dialog.play.js';
import { DialogGenericLoading, DialogLoading } from './Dialog.loading.js';

import { DIALOG_SCREEN_STATES } from './Config.EJX.js';

import CloseImg from '../images/close.svg';
import EJXLogoImg from '../images/ejx-logo.svg';
import { DialogNeedsSave } from './Dialog.needsSave.js';
import { DialogAbout } from './Dialog.about.js';
import { DialogSignup } from './Dialog.signup.js';
import { DialogSignupConfirm } from './Dialog.signupConfirm.js';

function Dialog( editor ) {

	const signals = editor.signals;
	const config = editor.config;

	let dialogScreenState = null;
	let pendingScreenState = null;

	const container = new UIPanel();
	container.setId( 'dialog' );
	container.setPosition( 'absolute' );
	container.setWidth( '100vw' );
	container.setHeight( '100vh' );
	container.setDisplay( 'none' );

	//

	// Handlers

	const closeDialog = () => {

		dialog.open = false;
		dialog.close();
		container.setDisplay( 'none' );

	};

	const openDialog = ( screenState ) => {

		container.setDisplay( '' );
		dialog.open = true;

		if ( screenState === DIALOG_SCREEN_STATES.NEW ) {

			navigateToScreen( newScreen );
			dialogScreenState = screenState;
			return;

		} else if (screenState === DIALOG_SCREEN_STATES.ABOUT) {
            navigateToScreen(aboutScreen);
			dialogScreenState = screenState;
            return;
        } else if (screenState === DIALOG_SCREEN_STATES.LOADING) {
			navigateToScreen(genericLoadingScreen);
			dialogScreenState = screenState;
			return;
		}


		if ( isLoggedIn() ) {

			switch ( screenState ) {

				case DIALOG_SCREEN_STATES.NEW:
					navigateToScreen( newScreen );
					break;
				case DIALOG_SCREEN_STATES.PROJECT_TYPE:
					navigateToScreen( projectTypeScreen );
					break;
				case DIALOG_SCREEN_STATES.THUMBNAIL:
					navigateToScreen( thumbnailScreen );
					break;
				case DIALOG_SCREEN_STATES.OPEN:
					navigateToScreen( openScreen );
					// populateProjectSelectMenu( config.getKey( 'user/projectList' ) );
					break;
                case DIALOG_SCREEN_STATES.NEEDS_SAVE:
                    navigateToScreen( needsSaveScreen );
                    break;
                case DIALOG_SCREEN_STATES.SAVE_AS:
                    navigateToScreen( savingAsScreen );
                    break;
				case DIALOG_SCREEN_STATES.SAVING:
					// Has a project been created?
					if ( !config.getKey( 'project/id' ) ) {

						// No, so we need to create one
						navigateToScreen( savingAsScreen );
						pendingScreenState = DIALOG_SCREEN_STATES.SAVING;

					} else {

						// Yes, so we can save
						navigateToScreen( savingScreen );
						signals.requestEJXSave.dispatch();

					}

					break;
				case DIALOG_SCREEN_STATES.PLAY:
					navigateToScreen( playScreen );
					break;

			}

			dialogScreenState = screenState;

		} else {

            switch ( screenState ) {
                case DIALOG_SCREEN_STATES.LOGIN:
                    navigateToScreen( loginScreen );
                    break;
                case DIALOG_SCREEN_STATES.SIGN_UP:
                    navigateToScreen( signupScreen );
                    break;
                case DIALOG_SCREEN_STATES.SIGN_UP_CONFIRM:
                    navigateToScreen( signupConfirmScreen );
                    break;
                default:
                    pendingScreenState = screenState;
                    screenState = DIALOG_SCREEN_STATES.LOGIN
                    navigateToScreen( loginScreen );
                    break;
            }

			dialogScreenState = screenState;

		}

		dialog.show();

	};

	const openSaveDialog = () => {

		container.setDisplay( '' );
		dialog.open = true;
		navigateToScreen( savingScreen );
		dialog.show();

	};

	const isLoggedIn = () => {

		return config.getKey( 'auth/isLoggedIn' );

	};


	const onLogout = () => {

		config.clearUser();

		signals.logoutSuccess.dispatch();

	};


	const navigateToScreen = ( newScreen ) => {

		transitionScreen( currentScreen.dom, newScreen.dom );
		currentScreen = newScreen;

	};

	// Helpers

	const transitionScreen = ( currentScreen, newScreen ) => {

		dialog.removeChild( currentScreen );
		dialog.appendChild( newScreen );

	};

	const dialogBg = new UIPanel();
	dialogBg.setPosition( 'absolute' );
	dialogBg.setWidth( '100vw' );
	dialogBg.setHeight( '100vh' );
	dialogBg.setBackgroundColor( '#000' );
	dialogBg.setOpacity( '0.5' );
	container.dom.appendChild( dialogBg.dom );

	const closeIcon = document.createElement( 'img' );
	closeIcon.src = CloseImg.src;

	const closeDialogButton = new UIButton();
	closeDialogButton.dom.id = 'closeDialogButton';
	closeDialogButton.dom.appendChild( closeIcon );
	closeDialogButton.onClick( () => signals.closeDialog.dispatch() );

	const ejxLogo = document.createElement( 'img' );
	ejxLogo.src = EJXLogoImg.src;
	ejxLogo.style.top = '10px';
	ejxLogo.style.left = '30px';
    ejxLogo.style.position = 'absolute';
	ejxLogo.style.width = '100px';
	ejxLogo.style.height = 'auto';
	ejxLogo.style.display = 'block';


	const dialog = document.createElement( 'DIALOG' );
	dialog.open = false;
	dialog.id = 'ejxDialog';

	dialog.appendChild( closeDialogButton.dom );
	dialog.appendChild( ejxLogo );
	container.dom.appendChild( dialog );


	// Create dialog screens
	const signupScreen = new DialogSignup( editor );
	const signupConfirmScreen = new DialogSignupConfirm( editor );
	const loginScreen = new DialogLogin( editor );
	container.add( loginScreen );

    const aboutScreen = new DialogAbout( editor );
	const savingScreen = new DialogSave( editor );
	const savingAsScreen = new DialogSaveAs( editor );
	const openScreen = new DialogOpen( editor );
	const newScreen = new DialogNew( editor );
	const projectTypeScreen = new DialogProjectType( editor );
	const thumbnailScreen = new DialogThumbnail( editor );
	const playScreen = new DialogPlay( editor );
	const genericLoadingScreen = new DialogGenericLoading( editor );
	const loadingScreen = new DialogLoading( editor );
    const needsSaveScreen = new DialogNeedsSave( editor );


	dialog.appendChild( loginScreen.dom );
	dialog.appendChild( loadingScreen.dom );
	let currentScreen = loginScreen;

	// Event Listeners

	signals.launchDialog.add( function ( screenState = DIALOG_SCREEN_STATES.LOGIN ) {

		openDialog( screenState );

	} );

	signals.closeDialog.add( function () {

		closeDialog();

	} );

	signals.loginSuccess.add( function () {

		if ( pendingScreenState ) {

			signals.launchDialog.dispatch( pendingScreenState );
			pendingScreenState = null;

		}

	} );

	signals.logout.add( function () {

		onLogout();

	} );

	signals.launchSaveDialog.add( function () {

		openSaveDialog();

	} );

	return container;

}

export { Dialog };
