import { EJX_COGNITO_USERPOOL_ID, EJX_COGNITO_CLIENT_ID } from '../Config.EJX';
import { 
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

/**
 * Base type for authentication errors.
 * @abstract
 * @class
 * @classdesc 
 */
class BaseAuthError extends Error {
    withCause(cause) {
        this.cause = cause;
        return this;
    }
}

/**
 * Error type for unhandled/panic exceptions.
 * @class
 * @classdesc 
 */
class UnknownAuthError extends BaseAuthError { }
class UserNotFoundError extends BaseAuthError { }
class UsernameExistsError extends BaseAuthError { }
class IncorrectDetailsError extends BaseAuthError { }
class InvalidInputError extends BaseAuthError { }
class InvalidPasswordError extends BaseAuthError { }
class UserNotConfirmedError extends BaseAuthError {
    constructor(username) {
        super(`The user "${username}" has not confirmed their email.`)
    }
}
class NewPasswordRequiredError extends BaseAuthError {
    constructor(userAttributes, username) {
        super('User requires a new password.')
        this.userAttributes = userAttributes;
        this.username = username;
    }
}

/** @typedef {(UnknownAuthError|NewPasswordRequiredError|InvalidInputError|UserNotConfirmedError|UsernameExistsError|UserNotFoundError|IncorrectDetailsError)} AuthError */
export const AuthErrors = { 
    UnknownAuthError,
    UserNotFoundError,
    UsernameExistsError,
    IncorrectDetailsError,
    InvalidInputError,
    NewPasswordRequiredError,
    InvalidPasswordError,
    UserNotConfirmedError,
};

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const AuthUtils = {
    _userPool: undefined,
    /**
     * @returns {CognitoUserPool} 
     */
    userPool() {
        if (!this._userPool) {
            this._userPool = new CognitoUserPool({
                UserPoolId: EJX_COGNITO_USERPOOL_ID, // Your user pool id here
                ClientId: EJX_COGNITO_CLIENT_ID, // Your client id here
            });
        }
        return this._userPool;
    },
    _cognitoUser: undefined,
    /**
     * @param {string} username 
     * @returns {CognitoUser} 
     */
    cognitoUser(username) {
        console.log(this._cognitoUser);
        if (!this._cognitoUser || this._cognitoUser.username !== username) {
            this._cognitoUser = new CognitoUser({
                Pool: this.userPool(),
                Username: username,
            })
        }
        return this._cognitoUser
    },

    /**
     * @param {string} username 
     * @param {string} password 
     * @returns {Promise<CognitoUserSession>}
     * @throws {AuthError}
     */
    async login(username, password) {
        var authenticationData = {
            Username: username,
            Password: password,
        };
        // eslint-disable-next-line no-undef
        var authenticationDetails = new AuthenticationDetails(
            authenticationData
        );
        // eslint-disable-next-line no-undef
        const cognitoUser = this.cognitoUser(username);

        const result = await new Promise((res, rej) => {
            cognitoUser.authenticateUser( authenticationDetails, {
                onSuccess: async function ( session ) {
                    const accessToken = session.accessToken.jwtToken;
                    const refreshToken = session.refreshToken.token;
                    const idToken = session.idToken.jwtToken;
                    if (username !== session.getIdToken().payload[ 'cognito:username' ]) {
                        throw new UnknownAuthError("After signup, cognito username doesn't match provided.")
                    }
                    const email = session.getIdToken().payload.email;

                    const config = editor.config
                    const signals = editor.signals;

                    config.setKey(
                        'auth/IdToken', idToken,
                        'auth/AccessToken', accessToken,
                        'auth/RefreshToken', refreshToken,
                        'user/username', username,
                        'user/email', email,
                        'auth/isLoggedIn', true
                    );


                    const workspaces = await editor.ejxAPI.getWorkspacesByUser( username );
                    if ( workspaces && workspaces.length > 0 ) {

                        config.setKey( 'user/workspace', workspaces[ 0 ].workspaceId );

                        const projects = await editor.ejxAPI.getProjects( config.getKey( 'user/workspace' ), config.getKey( 'user/username' ) );
                        config.setKey( 'user/projectList', projects );

                        signals.loginSuccess.dispatch();

                    } else {

                        const newAccount = await editor.ejxAPI.createAccount( username, username, email );
                        config.setKey( 'user/workspace', newAccount.workspace.workspaceId );

                        signals.loginSuccess.dispatch();

                    }

                    res(session)
                },

                onFailure: function ( err ) {
                    if (err instanceof Error && typeof(err.code) === 'string' ) {
                        if (err.code === 'UserNotConfirmedException') {
                            return rej(new UserNotConfirmedError(username));
                        } else if (err.code === 'UserNotFoundException') {
                            return rej(new UserNotFoundError())
                        } else if (err.code === 'NotAuthorizedException') {
                            return rej(new IncorrectDetailsError());
                        }
                    }
                    return rej(new UnknownAuthError('Error while signing up').withCause(err))
                },

                newPasswordRequired: function ( userAttributes ) {
                    rej(new NewPasswordRequiredError(userAttributes, username))
                },
            } );
        })
    },

    /**
     * Validates password when signing up / changing password.
     * @param {string} password 
     * @returns {string|undefined} Error string if any issue exists.
     */
    validatePassword(password) {
        const passwordContainsNumber = /\d+/.test(password);
        const passwordContainsCapital = /[A-Z]/.test(password);
        if (password.length < 8) {
            return 'Password must be at least 8 characters long.'
        } else if (!passwordContainsNumber || !passwordContainsCapital) {
            return 'Password must contain an upper case character and a number.'
        }
    },
    /**
     * Validates input params when signing up.
     * @param {string} email 
     * @param {string} username 
     * @param {string} password 
     * @returns {string|undefined} Error string if any issue exists.
     */
    validateSignup(email, username, password) {
        if (username.length === 0 || email.length === 0 || password.length === 0) {
            return 'Please fill out all the fields.'
        } else if (!REGEX_EMAIL.test(email)) {
            return 'Must use a valid email address.'
        } else if (username.length < 3) {
            return 'Username must be at least 3 letters.'
        } else if (username.length > 32) {
            return "Username can't be longer than 32 letters."
        } 
        const passwordError = this.validatePassword(password);
        if (passwordError) {
            return passwordError;
        }
    },
    /**
     * @param {string} email 
     * @param {string} username 
     * @param {string} password 
     * @returns {Promise<import('amazon-cognito-identity-js').ISignUpResult>}
     */
    signup(email, username, password) {
        const reasonMessage = AuthUtils.validateSignup(email, username, password);
        if (reasonMessage) throw new InvalidInputError(reasonMessage);

        const attributeList = [];
        attributeList.push(new CognitoUserAttribute({
            Name: 'email',
            Value: email,
        }));

        return new Promise((res, rej) => {
            this.userPool().signUp(username, password, attributeList, null, (err, data) => {
                if (err) {
                    if (err.name === 'UsernameExistsException') {
                        return rej(new UsernameExistsError(username).withCause(err));
                    }
                    return rej(new UnknownAuthError('While signing up').withCause(err));
                }
                else return res(data);
            });
        });
    },

    /**
     * @param {string} username 
     * @param {string} confirmationCode 
     */
    validateVerify(username, confirmationCode) {
        if (!username || username.length === 0) {
            return 'Please provide a username.'
        } else if (confirmationCode.length === 0) {
            return 'Please provide a confirmationCode.'
        } else if (confirmationCode.length < 6) {
            return 'Confirmation codes are 6 characters long.'
        }
    },
    /**
     * Verifies an unverified user.
     * @param {string} username 
     * @param {string} confirmationCode 
     * @returns {Promise<any>}
     */
    verify(username, confirmationCode) {

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool(),
        })
        return new Promise((res, rej) => {
            cognitoUser.confirmRegistration(confirmationCode, true, (err, data) => {
                if (err) {
                    if (err.name === 'CodeMismatchException') {
                        return rej(new AuthErrors.InvalidInputError('Code does not match.').withCause(err));
                    }
                    return rej(err);
                } else return res(data);
            });
        });
    },

    resendConfirmation(username) {
        // Resend the confirmation code to the user's email
        return new Promise((res, rej) => {
            this.cognitoUser(username).resendConfirmationCode(function(err, result) {
                if (err) {
                    return rej(err);
                }
                return res(result)
            });
        })
    },

    async completeNewPassword(newPasswordRequiredErr, password) {
        return new Promise((res, rej) => {
            const username = newPasswordRequiredErr.username;
            const cognitoUser = this.cognitoUser(username);
            cognitoUser.completeNewPasswordChallenge(password, {}, {
                onSuccess: async function (session) {
                    const accessToken = session.accessToken.jwtToken;
                    const refreshToken = session.refreshToken.token;
                    const idToken = session.idToken.jwtToken;
                    if (username !== session.getIdToken().payload[ 'cognito:username' ]) {
                        throw new UnknownAuthError("After signup, cognito username doesn't match provided.")
                    }
                    const email = session.getIdToken().payload.email;

                    const config = editor.config
                    const signals = editor.signals;

                    config.setKey(
                        'auth/IdToken', idToken,
                        'auth/AccessToken', accessToken,
                        'auth/RefreshToken', refreshToken,
                        'user/username', username,
                        'user/email', email,
                        'auth/isLoggedIn', true
                    );


                    const workspaces = await editor.ejxAPI.getWorkspacesByUser( username );
                    if ( workspaces && workspaces.length > 0 ) {

                        config.setKey( 'user/workspace', workspaces[ 0 ].workspaceId );

                        const projects = await editor.ejxAPI.getProjects( config.getKey( 'user/workspace' ), config.getKey( 'user/username' ) );
                        config.setKey( 'user/projectList', projects );

                        signals.loginSuccess.dispatch();

                    } else {

                        const newAccount = await editor.ejxAPI.createAccount( username, username, email );
                        config.setKey( 'user/workspace', newAccount.workspace.workspaceId );

                        signals.loginSuccess.dispatch();

                    }

                    res(session)
                },

                onFailure: function ( err ) {
                    if (err instanceof Error && typeof(err.code) === 'string' ) {
                        if (err.code === 'InvalidPasswordException') {
                            return rej(new InvalidPasswordError(err.message));
                        } else if (err.code === 'UserNotConfirmedException') {
                            return rej(new UserNotConfirmedError(username));
                        } else if (err.code === 'UserNotFoundException') {
                            return rej(new UserNotFoundError());
                        } else if (err.code === 'NotAuthorizedException') {
                            return rej(new IncorrectDetailsError());
                        }
                    }
                    return rej(new UnknownAuthError('Error while signing up').withCause(err))
                },
            })
        })
    }

}

export default AuthUtils;
