import { zipSync, unzip, zip, strToU8 } from 'fflate';
import { Zipped as ProjectTemplateZipped } from "@eyejack/ejx-editor-template";
import { fileTypeFromBuffer } from 'file-type';
import { AddObjectCommand } from '../commands/AddObjectCommand';
import { AddScriptCommand } from '../commands/AddScriptCommand';
import { MultiCmdsCommand } from '../commands/MultiCmdsCommand';
import { traverseFind } from './scene';
import { VersioningUtils } from '../versioning';

/**
 * @module 
 * Loading Utils.
 *
 * These utilities help import export app.json projects.
 * We need to support importing from 3 different schemas.
 *
 * 1: From an app.json file
 *   - All of the binary assets are embedded within this file as base64
 *   - This is what the editor operates on.
 *
 * 2: (bytes map) From a record of `Record<string (filepath), Uint8Array (file contents as bytes)>`
 *   - This is what we get when we unzip a zipped project.
 *   - Binary assets are external from the app.json, stored in the record
 *   - This is how we receive projects stored in the cloud (once unzipped with `unzipSync`)
 *
 * 3: (files map) From a record of `Record<string (filepath), File (file contents)>`
 *   - This is what we get when we drag and drop a folder into the viewport.
 *   - Binary assets are external from the app.json, stored in the record
 *
 * If you have a zipped folder you can use `unzipFile` or `unzip` to get a bytes map.
 * You can use `internalizeProjectAssets` to convert from a bytes map -> an app json.
 * When we're exporting we need to use `externalizeProjectAssets` and then `zipSync`.
 */
const Utils = {

    /**
     * Traverses the app.json representation of a scene, calling 'hander'
     * on every scene object.
     * @param {object} object Usually this will be `appJson.scene?.object`
     * @param {(obj: object) => void}
     */
    traverseAppJsonObject(object, handler) {
        handler(object)
        if (object.children) {
            for (const c of object.children) {
                this.traverseAppJsonObject(c, handler)
            }
        }
    },

    /**
     * @param {Uint8Array} bytes 
     * @returns {Promise<import('fflate').Unzipped>}
     */
    unzipFile(bytes) {
        return new Promise( ( res, rej ) => {
            unzip( bytes, ( err, unzipped ) => {
                if ( err ) rej( err );
                else res( unzipped );
            } );
        } );
    }
}

/** Utilities to help when transforming between the 3 different import/export types. */
const TransformUtils = {
    /**
     * Converts bytes to base64 data uri
     * @param {Uint8Array} bytes 
     * @param {undefined|string} [fallbackType=undefined] Fallback type (if fileTypeFromBuffer not work)
     * @returns {Promise<string>}
     */
    async bytesToDataUri(bytes, fallbackType = undefined) {
        let resolvedType = await fileTypeFromBuffer(bytes.buffer);
        console.log('bytesToDataUri', resolvedType);
        const t = (resolvedType && resolvedType.mime) || fallbackType;

        const blob = new Blob([bytes], { type: t });
        return await new Promise((res, rej) => {
            const fr = new FileReader();
            fr.onload = () => {
                res(fr.result);
            }
            fr.onerror = () => {
                rej(fr.error)
            }
            fr.readAsDataURL(blob);
        })
    },

    /**
     * Converts a File to base64
     * @param {string} dataUri 
     * @param {string} fileName 
     * @returns {Promise<Uint8Array>}
     */
    async dataUriToBytes(dataUri, fileName) {
        const byteString = atob(dataUri.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return ia;
    },
    /**
     * Converts a record of filepaths and bytes into a File Map (KV of path:File).
     * @param {Record<string, File>} bytesFiles 
     * @returns {Promise<string, Uint8Array>} 
     */
    async filesMapToBytesMap(filesMap) {
        const result = {};
        for (const [path, file] of Object.entries(filesMap)) {
            /** @type {File} */
            const f = file;
            const bytes = new Uint8Array(await f.arrayBuffer())
            result[path] = bytes;
        }
        return result;
    },

    EXTENSION_TYPE_LOOKUP: {
        'aac': 'audio/aac',
        'apng': 'image/apng',
        'avi': 'video/x-msvideo',
        'bmp': 'image/bmp',
        'gif': 'image/gif',
        'jpeg': 'image/jpeg',
        'jpg': 'image/jpeg',
        'mp3': 'audio/mpeg',
        'mp4': 'video/mp4',
        'mpeg': 'audio/mpeg',
        'oga': 'audio/ogg',
        'ogv': 'video/ogg',
        'ogx': 'application/ogg',
        'opus': 'audio/opus',
        'png': 'image/png',
        'svg': 'image/svg+xml',
        'wav': 'audio/wav',
        'weba': 'audio/webm',
        'webm': 'video/webm',
        'webp': 'image/webp',
        '3gp': 'video/3gpp',
        '3g2': 'video/3gpp2',
    },
    
    /**
     * Returns the mimetype of a filename (using file extension).  Returns undefined if unknown.
     * @param {string} filename 
     * @returns {string|undefined}
     */
    getMimeTypeForFilename(filename) {
        const segments = filename.split('.');
        if (segments.length === 0) return;

        const ext = segments[segments.length - 1];
        const mimeType = this.EXTENSION_TYPE_LOOKUP[ext];
        return mimeType
    },

    /**
     * @param {object} appJson - The app.json data
     * @param {Record<string, Uint8Array>} folderContext - Folder the project is within.
     * @see externalizeProjectAssets
     */
    async internalizeProjectAssets(appJson, folderContext) {
        const que = [];
        Utils.traverseAppJsonObject(appJson.scene.object, object => {
            if (object.userData && object.userData.video_filename && !object.userData.video_file_64) {
                que.push(new Promise((res, rej) => {
                    const filePath = Object.keys(folderContext).find(k => k.endsWith(object.userData.video_filename));
                    /** @type {Uint8Array|undefined} */
                    const file = folderContext[filePath];
                    if (!file) {
                        return rej(new Error(`Could not find video file for "${object.userData.video_filename}" which is referenced in the scene.`));
                    }

                    const fallbackType = this.getMimeTypeForFilename(filePath);
                    this.bytesToDataUri(file, fallbackType).then(dataUri => {
                        object.userData.video_file_64 = dataUri;
                        res();
                    })
                }))
            }

            if (object.userData && object.userData.sound_filename && !object.userData.sound_file_64) {
                que.push(new Promise((res, rej) => {
                    const filePath = Object.keys(folderContext).find(k => k.includes(object.userData.sound_filename));
                    /** @type {Uint8Array|undefined} */
                    const file = folderContext[filePath];
                    if (!file) {
                        return rej(new Error(`Could not find audio file for "${object.userData.video_filename}" which is referenced in the scene.`));
                    }
                    const fallbackType = this.getMimeTypeForFilename(filePath);
                    this.bytesToDataUri(file, fallbackType).then(dataUri => {
                        object.userData.sound_file_64 = dataUri;
                        res();
                    })
                }))
            }
        });

        const results = await Promise.allSettled(que);
        console.warn(results);

        return appJson
    },

    ASSET_PREFIX: 'assets/',

    /**
     * @param {object} appJson (mutates) 
     * @param {Record<string, File>} folderContext (mutates)
     * @see internalizeProjectAssets
     */
    async externalizeProjectAssets(appJson, folderContext) {
        const que = [];

        Utils.traverseAppJsonObject(appJson.scene.object, object => {
            if (object.userData && object.userData.video_file_64) {
                que.push(new Promise((res, rej) => {
                    this.dataUriToBytes(object.userData.video_file_64, object.userData.video_filename).then(file => {
                        /** @type {string} */
                        let path = object.userData.video_filename || object.userData.uuid + '.ejxasset';
                        if (!path.startsWith(this.ASSET_PREFIX)) {
                            path = this.ASSET_PREFIX + path;
                        }
                        folderContext['content/' + path] = file;
                        object.userData.video_filename = path;
                        delete object.userData.video_file_64;
                        res();
                    });
                }));
            }

            if (object.userData && object.userData.sound_file_64) {
                que.push(new Promise((res, rej) => {
                    this.dataUriToBytes(object.userData.sound_file_64, object.userData.sound_filename).then(file => {
                        let path = object.userData.sound_filename || object.userData.uuid + '.ejxasset';
                        if (!path.startsWith(this.ASSET_PREFIX)) {
                            path = this.ASSET_PREFIX + path;
                        }
                        folderContext['content/' + path] = file;
                        object.userData.sound_filename = path;
                        delete object.userData.sound_file_64;
                        res();
                    });
                }));
            }
        });

        const results = await Promise.allSettled(que);
        console.warn(results);

        return appJson
    },

    /**
     * Decodes the message pack representation of an app (app.ejx file).  Applying patches to turn it into what an app.json expects.
     * The app.ejx is a deprecated format so wont need to be considered for version migrations etc.
     * @param {any} editor 
     * @param {Uint8Array} appEjx 
     */
    async decodeAppEjx(editor, appEjx) {
        const msgpack = await import('@msgpack/msgpack');
        const data = msgpack.decode(appEjx);

        if (data.scene && data.scene.images) {
            editor.signals.setLoadingStatus.dispatch('Decoding images...')
            for (const img of data.scene.images) {
                img.url = await TransformUtils.bytesToDataUri(img.url);
            }
        }

        editor.signals.setLoadingStatus.dispatch('Decoding video and audio...')
        const que = [];

        Utils.traverseAppJsonObject(data.scene.object, obj => {
            if (obj.userData && obj.userData.video_binary) {
                que.push(new Promise((res, rej) => {
                    const fallbackType = this.getMimeTypeForFilename(filePath);
                    TransformUtils.bytesToDataUri(obj.userData.video_binary, fallbackType).then(dataUri => {
                        delete obj.userData.video_binary;
                        obj.userData.video_file_64 = dataUri;
                        res();
                    })
                }))
            }
            if (obj.userData && obj.userData.sound_binary) {
                que.push(new Promise((res, rej) => {
                    const fallbackType = this.getMimeTypeForFilename(filePath);
                    TransformUtils.bytesToDataUri(obj.userData.sound_binary, fallbackType).then(dataUri => {
                        delete obj.userData.sound_binary;
                        obj.userData.sound_file_64 = dataUri;
                        res();
                    })
                }))
            }
        })

        const results = await Promise.allSettled(que);


        return data;
    }
}

export const ImportUtils = {
    /**
     * Checks if a bytes or files map is an EJX project.
     * @param {any} editor 
     * @param {Record<string, Uint8Array>|Record<string, File>} map 
     */
    isMapAnEjxProject(editor, map) {
        return !!this.getAppDataFromBytesMap(editor, map);
    },

    APP_DATA_FILE_REGEX: /(\/|^)app.(json|ejx)/,

    /**
     * Gets the app.json or app.ejx from a bytes map.
     * @param {any} editor 
     * @param {Record<string, Uint8Array>} bytesMap 
     * @returns {[path: string, data: Uint8Array]}
     */
    getAppDataFromBytesMap(editor, bytesMap) {
        return Object.entries(bytesMap).find(([row, bytes]) => {
            return this.APP_DATA_FILE_REGEX.test(row);
        });
    },
    /**
     * Gets the app.json or app.ejx from a files map.
     * @param {any} editor 
     * @param {Record<string, File>} filesMap 
     * @returns {[path: string, data: File]}
     */
    getAppDataFromFilesMap(editor, filesMap) {
        return Object.entries(filesMap).find(([row, file]) => {
            return this.APP_DATA_FILE_REGEX.test(row);
        });
    },
    /**
     * Imports a project into an existing project.
     * @param {{}} editor 
     * @param {{}} data 
     */
    importFromAppJson(editor, data) {
        const latestData = VersioningUtils.migrateToLatest(data);

        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Importing...');

        var loader = new THREE.ObjectLoader();
        const projectName = latestData.project.projectTitle;
        const object = loader.parse(latestData.scene);

        const newRoot = new THREE.Group();
        newRoot.uuid = object.uuid;
        newRoot.name = projectName ? projectName : 'Imported Project';
        newRoot.add(...object.children);

        const cmds = [];
        cmds.push(new AddObjectCommand( editor, newRoot ));

        if (latestData.scripts) {
            for (const [uuid, scripts] of Object.entries(latestData.scripts)) {
                const object = traverseFind(newRoot, obj => obj.uuid === uuid);

                if (object) {
                    for (const script of scripts) {
                        cmds.push(new AddScriptCommand(editor, object, script))
                    }
                } else {
                    pushNotification(editor, {
                        type: 'warning',
                        title: `Failed to import ${scripts.length} scripts on object.`,
                        description: `Script names: ${scripts.map(s => `'${s.name}'`).join(', ')}`,
                    })
                }
            }
        }

        const cmd = new MultiCmdsCommand( editor, cmds);

        editor.execute( cmd );
        return latestData;
    },

    /**
     * Imports a project into an exisitng project.
     * @param {{}} editor 
     * @param {Record<string, Uint8Array>} bytesMap 
     */
    async importFromBytesMap(editor, bytesMap) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Parsing...');

        const appData = this.getAppDataFromBytesMap(editor, bytesMap);
        if (!appData) throw new Error('Could not find an app.json or app.ejx file in the bytes map.');
        const [path, bytes] = appData;

        let data;
        if (path.endsWith('.ejx')) {
            data = await TransformUtils.decodeAppEjx(editor, bytes);
        } else {
            data = JSON.parse(new TextDecoder().decode(bytes));
        }

        await TransformUtils.internalizeProjectAssets(data, bytesMap)
        return this.importFromAppJson(editor, data);
    },
    /**
     * Imports a project into an existing project.
     * @param {any} editor 
     * @param {Uint8Array} zip 
     */
    async importFromZip(editor, zip) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Unpacking...');

        const bytesMap = await Utils.unzipFile(zip);
        return await this.importFromBytesMap(editor, bytesMap);
    },
    /**
     * Imports a project into an exisitng project.
     * @param {{}} editor 
     * @param {Record<string, File>} filesMap 
     */
    async importFromFilesMap(editor, filesMap) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Unpacking...');

        const bytesMap = await TransformUtils.filesMapToBytesMap(filesMap);
        return await this.importFromBytesMap(editor, bytesMap);
    },

    /**
     * Loads a project, replacing the current project.
     * @param {{}} editor 
     * @param {{}} data 
     */
    loadFromAppJson(editor, data) {
        const latestData = VersioningUtils.migrateToLatest(data);

        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Importing...');

        editor.clear();
        editor.config.clearProject();

        editor.fromJSON( latestData );

        return latestData;
    },

    /**
     * Loads a project, replacing the current project.
     * @param {{}} editor 
     * @param {Record<string, Uint8Array>} bytesMap 
     */
    async loadFromBytesMap(editor, bytesMap) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Parsing...');

        const appData = this.getAppDataFromBytesMap(editor, bytesMap);
        if (!appData) throw new Error('Could not find an app.json or app.ejx file in the bytes map.');
        const [path, bytes] = appData;

        let data;
        if (path.endsWith('.ejx')) {
            data = await TransformUtils.decodeAppEjx(editor, bytes);
        } else {
            data = JSON.parse(new TextDecoder().decode(bytes));
        }

        await TransformUtils.internalizeProjectAssets(data, bytesMap)
        return this.loadFromAppJson(editor, data);
    },
    /**
     * Loads a project, replacing the current project.
     * @param {any} editor 
     * @param {Uint8Array} zip 
     */
    async loadFromZip(editor, zip) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Unpacking...');

        const bytesMap = await Utils.unzipFile(zip);
        return await this.loadFromBytesMap(editor, bytesMap);
    },
    
    /**
     * Loads a project, replacing the current project.
     * @param {{}} editor 
     * @param {Record<string, File>} filesMap 
     */
    async loadFromFilesMap(editor, filesMap) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Unpacking...');

        const bytesMap = await TransformUtils.filesMapToBytesMap(filesMap);
        return await this.loadFromBytesMap(editor, bytesMap);
    }
}

export const ExportUtils = {
    /** @typedef {'Cube'|'Default'} EJXProjectType */

    /** @typedef {Object} EJXMetadataVersion
     * @property {number} editor
     * @property {number} three
     */

    /**
     * @typedef {Object} EJXMetadata
     * @property {EJXProjectType} type
     * @property {EJXMetadataVersion} version
     */

    /*
     * Returns the unzipped template for the current project type.
     * @param {*} editor - The editor global object
     * @returns {Record<string, Blob>} Object contain file blobs.
     */
    async getUnzippedTemplate( editor ) {
        const zipResponse = await fetch(ProjectTemplateZipped);
        const zipBuffer = await zipResponse.arrayBuffer();
        const zipData = Utils.unzipFile(new Uint8Array(zipBuffer));

        return zipData;
    },

    /**
     * Generates the metadata portion of the ejx.json
     * @param {import('../Editor')} editor 
     * @returns {EJXMetadata} EJX Metadata
     */
    generateEJXMetadata(editor) {
        return {
            type: editor.config.getKey( 'project/type' ),
            title: editor.config.getKey( 'project/title' ),
            version: {
                editor: 1,
                three: Number.parseInt(THREE.REVISION),
            }
        }
    },

    /**
     * Generates the bytesmap of a project in preperation for zipping.
     * @param {any} editor 
     * @returns {Promise<Record<string, Uint8Array>>}
     */
    async generateProjectBytesMap(editor) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Fetching resources...');
        const bytesMap = await this.getUnzippedTemplate( editor );

        editor.signals.setLoadingStatus.dispatch('Building project...');
        // 2. Insert the app.json content file.
        let output = structuredClone(editor.toJSON());
        output.metadata.type = 'App';
        delete output.history;

        await TransformUtils.externalizeProjectAssets(output, bytesMap);

        // This data is mirrored in the ejx.json and app.json (so editor can load just off the app.json file).
        const ejxData = this.generateEJXMetadata(editor);
        output.ejx = ejxData;

        output = JSON.stringify( output, null, '\t' );
        output = output.replace( /[\n\t]+([\d\.e\-\[\]]+)/g, '$1' );

        bytesMap[ 'content/app.json' ] = strToU8( output );

        const aabb = new THREE.Box3();
        aabb.setFromObject( editor.scene );

        // 3. Insert the ejx.json config file
        const ejxJson = {
            ...ejxData,
            content: 'content/app.js',
            'app-json-length': bytesMap[ 'content/app.json'].length,
            box: {
                min: aabb.min,
                max: aabb.max,
            }
        };

        output = JSON.stringify( ejxJson, null, '\t' );
        output = output.replace( /[\n\t]+([\d\.e\-\[\]]+)/g, '$1' );

        bytesMap[ 'content/ejx.json' ] = strToU8( output );
        return bytesMap;
    },

    /**
     * Zips a bytesMap into a single Uint8Array representing the zipped file.
     * @param {any} editor 
     * @param {Record<string, Uint8Array>} bytesMap 
     * @returns {} 
     */
    async zipBytesMap(editor, bytesMap) {
        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Bundling...');
        return new Promise((res, rej) => {
            zip(bytesMap, {}, (err, data) => {
                if (err) rej(err);
                else res(data)
            })
        })
    },

    /*
     * Exports the project as a zip that can be minted or stored in the EJX cloud.
     * @param {*} editor - The editor global object
     * @returns {Promise<Uint8Array>} Binary of the zip file.
     */
    async generateProjectZip(editor) {
        const filesMap = await this.generateProjectBytesMap(editor);
        // Clean empty files/folders
        for (const k in filesMap) {
            const bytes = filesMap[k];
            if (bytes.length === 0) {
                delete filesMap[k];
            }
        }
        return this.zipBytesMap(editor, filesMap);
    },
}
