import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import { pushNotification } from './EJXNotification.js';
import { templateSize } from './Sidebar.EJX.ProjectSize.js';
import { UIPanel } from './libs/ui.js';

import { createEJXButton, createHeading } from './utils.EJX.js';

function DialogSave( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

	const savingScreen = document.createElement( 'div' );
	savingScreen.appendChild( createHeading( 'Saving to EJX' ) );
	const savingScreenText = document.createElement( 'p' );
	savingScreenText.textContent = 'Saving your changes to the EJX cloud...';
	savingScreen.appendChild( savingScreenText );

	const saveSuccessScreen = document.createElement( 'div' );
	saveSuccessScreen.appendChild( createHeading( 'Save Successful!' ) );
	saveSuccessScreen.appendChild( createEJXButton( 'Close', () => signals.closeDialog.dispatch() ) );

	const tooLargeScreen = document.createElement( 'div' );
	tooLargeScreen.appendChild( createHeading( 'Project too large!' ) );
	tooLargeScreen.appendChild( createEJXButton( 'Close', () => signals.closeDialog.dispatch() ) );

	container.dom.appendChild( savingScreen );

	signals.requestEJXSave.add( async function ( ) {

		signals.loadingStarted.dispatch();
		signals.setLoadingStatus.dispatch( 'Preparing to save...' );
		signals.localSaveFinished.addOnce(async (size) => {
			size += templateSize;
			// Front-end protection against oversized projects
            const maxMb = editor.config.getKey('user/maxProjectSizeMb') || 50;
            const maxBytes = maxMb * 1024 * 1024;
			if(size > maxBytes){
				signals.closeDialog.dispatch();
				pushNotification(editor, {
					type: 'error',
					title: 'Error saving project',
					description: `The current project size is ${(size / 1024 / 1024).toFixed(2)}MB, the maximum size is 50MB.`
				});
				return;
			}
			// Try the back-end
			try {
				await ejxApi.saveProject(async (event) => { 
					const progress = Math.round(event.loaded * 100 / event.total);
					signals.setLoadingStatus.dispatch( `Saving... ${progress}%` );
				});
			} catch (error) {
				if (error instanceof XMLHttpRequest) {
					if (error.responseText.includes('EntityTooLarge')) {
						pushNotification(editor, {
							type: 'error',
							title: 'Error saving project',
							description: `The current project size is ${(size / 1024 / 1024).toFixed(2)}MB, the maximum size is 50MB.`
						});
					} else {
						pushNotification(editor, {
							type: 'error',
							title: 'Error saving project',
							description: 'An unknown issue occured.  Please contact us on discord.'
						})
					}
				} else if (error && error.response && error.response.data && error.response.data.code === 'FORBIDDEN') {
                    signals.loadingFinished.dispatch();
                    signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.SAVE_AS);
                    return;
                }
				signals.loadingFinished.dispatch();
				signals.closeDialog.dispatch();
			}
		});
		signals.localSave.dispatch();
	} );

	signals.ejxSaveFinished.add( function () {

		container.dom.removeChild( savingScreen );
		container.dom.appendChild( saveSuccessScreen );
		signals.loadingFinished.dispatch();


	} );

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		container.dom.appendChild( savingScreen );


	} );

	return container;

}

export { DialogSave };
