/**
 * Generates the initial state for the config
 * @returns {}
 */
export const generateStorage = () => {

	return {
		'language': 'en',

		'autosave': true,

		'project/id': '',
		'project/title': '',
		'project/editable': false,
		'project/type': 'EJXCube', // 'Scene' | 'EJXCube'
		'project/type/EJXCube/faceFront': 'all', // 'all' | uuid
		'project/type/EJXCube/faceRight': 'all', // 'all' | uuid
		'project/type/EJXCube/faceBack': 'all', // 'all' | uuid
		'project/type/EJXCube/faceLeft': 'all', // 'all' | uuid
		'project/type/EJXCube/faceTop': 'all', // 'all' | uuid
		'project/type/EJXCube/faceBottom': 'all', // 'all' | uuid

		'project/type/EJXCube/borderOn': true,
        'project/type/EJXCube/borderWidth': 0.0025,
        'project/type/EJXCube/borderFeather': 0.5,
        'project/type/EJXCube/borderColor': '#ffffff',
        'project/type/EJXCube/borderAlpha': 0.25,

		'project/renderer/antialias': true,
		'project/renderer/shadows': true,
		'project/renderer/shadowType': 1, // PCF
		'project/renderer/useLegacyLights': false,
		'project/renderer/toneMapping': 0, // NoToneMapping
		'project/renderer/toneMappingExposure': 1,
		'project/renderer/postprocessing': [],

		'settings/history': false,

		'settings/shortcuts/translate': 'w',
		'settings/shortcuts/rotate': 'e',
		'settings/shortcuts/scale': 'r',
		'settings/shortcuts/undo': 'z',
		'settings/shortcuts/focus': 'f',

		'minter/thumbnail/position': '',
		'minter/thumbnail/look': '',
		'minter/thumbnail/width': 1024,
		'minter/thumbnail/height': 1024,
		'minter/thumbnail/fov': 20,
		'minter/thumbnail/zoom': 1,

		'user/username': '',
		'user/email': '',
		'user/workspace': '',
		'user/projectList': [],
		'auth/IdToken': '',
		'auth/AccessToken': '',
		'auth/RefreshToken': '',
		'auth/ExpiresIn': '',
		'auth/isLoggedIn': false,


	};

};

function Config() {

	const name = 'threejs-editor';

	let storage = generateStorage();

	if ( window.localStorage[ name ] === undefined ) {

		window.localStorage[ name ] = JSON.stringify( storage );

	} else {

		const data = JSON.parse( window.localStorage[ name ] );

		for ( const key in data ) {

			storage[ key ] = data[ key ];

		}

	}

	return {
		getKey: function ( key ) {

			return storage[ key ];

		},

		setKey: function () { // key, value, key, value ...

			for ( let i = 0, l = arguments.length; i < l; i += 2 ) {

				storage[ arguments[ i ] ] = arguments[ i + 1 ];

			}

			window.localStorage[ name ] = JSON.stringify( storage );

			console.log(
				'[' + /\d\d\:\d\d\:\d\d/.exec( new Date() )[ 0 ] + ']',
				'Saved config to LocalStorage.',
			);

		},

        /**
         * Clears all metadata related to the content of project
         */
		clearProject: function () {

			this.setKey( 
				'project/editable', false,
				'project/type', 'EJXCube', // 'Scene' | 'EJXCube'
				'project/type/EJXCube/faceFront', 'all', // 'all' | uuid
				'project/type/EJXCube/faceRight', 'all', // 'all' | uuid
				'project/type/EJXCube/faceBack', 'all', // 'all' | uuid
				'project/type/EJXCube/faceLeft', 'all', // 'all' | uuid
				'project/type/EJXCube/faceTop', 'all', // 'all' | uuid
				'project/type/EJXCube/faceBottom', 'all', // 'all' | uuid

                'project/type/EJXCube/borderOn', true,
                'project/type/EJXCube/borderWidth', 0.0025,
                'project/type/EJXCube/borderFeather', 0.5,
                'project/type/EJXCube/borderColor', '#ffffff',
                'project/type/EJXCube/borderAlpha', 0.25,

				'project/renderer/postprocessing', [],
				'project/renderer/antialias', true,
				'project/renderer/shadows', true,
				'project/renderer/shadowType', 1, // PCF
				'project/renderer/useLegacyLights', false,
				'project/renderer/toneMapping', 0, // NoToneMapping
				'project/renderer/toneMappingExposure', 1,
				'minter/thumbnail/fov', 20,
				'minter/thumbnail/zoom', 1,
			);

		},

        /**
         * Clears all metadata related to the identification of project
         * Clearing this means the project will no longer be associated with an project in the cloud and
         * the user will be prompted to provide a project id upon next save.
         */
        clearProjectId: function () {
			this.setKey( 
                'project/id', '',
				'project/title', '',
            );
        },

		clearUser: function () {

			this.setKey( 'user/username', '',
				'user/email', '',
				'user/workspace', '',
				'user/projectList', [],
                'user/capabilities', [],
                'user/maxProjectSizeMb', 50,
				'auth/IdToken', '',
				'auth/AccessToken', '',
				'auth/RefreshToken', '',
				'auth/ExpiresIn', '',
				'auth/isLoggedIn', false
			);

		},

	};

}

export { Config };
