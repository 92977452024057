import { UIDiv } from "./libs/ui";
import { createHeading } from "./utils.EJX";
import '../css/EJXNotification.css';

/**
 * This module contains code for a notification system.
 */

/**
 * @typedef {Object} NotificationModel
 * @property {'error'|'warning'|'success'} type
 * @property {string} title
 * @property {string?} description
 * @property {timeout=5000} timeout How long the notification is visible for
 * @property {(() => void)?} clickHandler Optional action to trigger when clicking on a notification
 */

/**
 * @param {NotificationModel} model 
 */
function Notification(model) {
    const container = new UIDiv();
    container.addClass('EJXNotification');
    container.addClass(`EJXNotification__${model.type ? model.type : 'success'}`);

    const title = createHeading(model.title);
    title.classList.add('EJXNotification_Title');
    container.dom.append(title);

    if (model.description) {
        const description = createHeading(model.description);
        description.classList.add('EJXNotification_Description');
        container.dom.append(description);
    }

    return container;
}

export function NotificationRoot(editor) {
    const container = new UIDiv();
    container.addClass('EJXNotificationRoot');

    editor.signals.pushNotification.add((model) => {

        const notification = Notification(model);
        container.add(notification);
        let removed = false;
        notification.dom.addEventListener('click', () => {
            if (model.clickHandler) model.clickHandler();

            removed = true;
            container.remove(notification);
        })

        setTimeout(() => {
            if (!removed) container.remove(notification);
        }, model.timeout ? model.timeout : 8000);

    })

    return container;
}

/**
 * @param {import('./Editor')} editor 
 * @param {NotificationModel} model 
 */
export function pushNotification(editor, model) {
    editor.signals.pushNotification.dispatch(model);
}
